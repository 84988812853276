<file-drop-container (filesDropped)="filesDropped($event)" [enabled]="uploadEnabled">
    <data-table [dataSource]="dataSource" itemsName="files"
                [showCheckbox]="canSelect()"
                class="responsive-material-table"
                matSort
                [supportedViewTypes]="[ViewTypes.Grid, ViewTypes.Table]"
                [viewType]="ViewTypes.Grid">
        <div headerActions>
            <h2 *ngIf="title" trans>{{title}}</h2>
            <file-entry-upload-button
                    [hidden]="!uploadEnabled"
                    [endpoint]="endpoint"
                    [acceptType]="filterType"
                    [ownershipType]="ownershipType"
                    [ownershipId]="ownershipId"
                    (filesUploaded)="filesUploaded()"></file-entry-upload-button>
        </div>

        <div *ngIf="mode === 'manage'" headerSelectedActions>
            <button mat-icon-button [matTooltip]="'Delete selected' | trans"
                    (click)="maybeDeleteSelectedEntries()"
                    *ngIf="canDelete()">
                <mat-icon svgIcon="delete"></mat-icon>
            </button>
        </div>

        <ng-container contentTableView>
            <ng-container *ngFor="let column of columns">
                <ng-container [ngSwitch]="column">

                    <ng-container *ngSwitchCase="'name'">
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header trans>Name
                            </mat-header-cell>
                            <mat-cell *matCellDef="let entry"
                                      data-label="Name:">
                                <a [href]="entry.url"
                                   target="_blank">{{entry.name}}</a>
                            </mat-cell>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'file_name'">
                        <ng-container matColumnDef="file_name">
                            <mat-header-cell *matHeaderCellDef trans>File Name
                            </mat-header-cell>
                            <mat-cell *matCellDef="let entry" data-label="File Name:">
                                <a [href]="entry.url"
                                   target="_blank">{{entry.file_name}}</a>
                            </mat-cell>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'type'">
                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef mat-sort-header trans>Type
                            </mat-header-cell>
                            <mat-cell *matCellDef="let entry"
                                      data-label="Type:"> {{entry.type}} </mat-cell>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'public'">
                        <ng-container matColumnDef="public">
                            <mat-header-cell *matHeaderCellDef mat-sort-header trans>
                                Public
                            </mat-header-cell>
                            <mat-cell *matCellDef="let entry" data-label="Public:"
                                      trans> {{entry.public ? 'Yes' : 'No'}} </mat-cell>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'file_size'">
                        <ng-container matColumnDef="file_size">
                            <mat-header-cell *matHeaderCellDef mat-sort-header trans>Size
                            </mat-header-cell>
                            <mat-cell *matCellDef="let entry"
                                      data-label="File Size:"> {{entry.file_size ? (entry.file_size | formattedFileSize) : '-'}} </mat-cell>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'owner'">
                        <ng-container matColumnDef="owner">
                            <mat-header-cell *matHeaderCellDef trans>Owner
                            </mat-header-cell>
                            <mat-cell *matCellDef="let entry">
                                <ng-container *ngIf="entry?.users[0]; else noUser">
                                    <img [src]="entry.users[0].avatar">
                                    <span>{{entry.users[0].display_name}}</span>
                                </ng-container>
                                <ng-template #noUser>-</ng-template>
                            </mat-cell>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'created_at'">
                        <ng-container matColumnDef="created_at">
                            <mat-header-cell *matHeaderCellDef mat-sort-header trans>
                                Created
                                At
                            </mat-header-cell>
                            <mat-cell *matCellDef="let entry"
                                      class="hidden-on-mobile"> {{entry.created_at | formattedDate}} </mat-cell>
                        </ng-container>
                    </ng-container>

                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *dataTableGridTileDef="let entry; let selectTpl = selectTpl">
            <div class="grid-item"
                 title="{{entry.name}}  •  {{entry.created_at! | formattedDateTime}}  •  {{entry.file_size! | formattedFileSize}}">
                <div class="grid-item-selector" *ngIf="canSelect()">
                    <div *ngTemplateOutlet="selectTpl"></div>
                </div>
                <div class="grid-item-content">
                    <ng-container *ngIf="entry.type !== 'image'">
                        <mat-icon svgIcon="file"></mat-icon>
                        <div class="grid-item-name">{{entry.name}}</div>
                    </ng-container>
                    <ng-container *ngIf="entry.type === 'image'">
                        <span class="grid-item-image-dimensions"><img-dimensions [src]="entry.url"></img-dimensions></span>
                        <img class="grid-item-image" src="{{entry.url}}"
                             loading="lazy"
                             alt="{{entry.name}}">
                    </ng-container>
                    <div class="action-buttons">
                        <a mat-flat-button mat-icon-button *ngIf="entry.type === 'image' && uploadEnabled"
                           (click)="editImage(entry)">
                            <mat-icon svgIcon="edit"></mat-icon>
                        </a>
                        <a mat-flat-button mat-icon-button [href]="entry.url"
                           target="_blank">
                            <mat-icon svgIcon="open-in-new"></mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
    </data-table>
</file-drop-container>
