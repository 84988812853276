import {Injectable} from '@angular/core';

@Injectable()
export class MediaContext {
    endpoint: string;
    ownershipType?: string;
    ownershipId?: string;

    setContext(endpoint: string, ownershipType: string, ownershipId: string): void {
        this.endpoint = endpoint;
        this.ownershipType = ownershipType;
        this.ownershipId = ownershipId;
    }
}
