<div style="max-height: 80vh">
    <h1>Format</h1>

    <div class="input-container-row">
        <div class="col-md-6 col-sm-12 input-container">
            <label for="image-edikt-width" trans>Breite</label>
            <input id="image-edikt-width" type="number" [(ngModel)]="width" (input)="sizeChanged()" name="width"
                   placeholder="Width">
        </div>
        <div class="input-container">
            <label for="image-edikt-height" trans>Höhe</label>
            <input id="image-edikt-height" type="number" [(ngModel)]="height" (input)="sizeChanged()" name="height"
                   placeholder="Height">
        </div>
    </div>

    <div class="input-container">
        <label for="image-edikt-ration" trans>Seitenverhältnis</label>
        <ng-select id="image-edikt-ration"
                   [(ngModel)]="aspectRatio"
                   [clearable]="false">
            <ng-option [value]="-1" trans>Sonstiges Format</ng-option>
            <!--suppress PointlessArithmeticExpressionJS -->
            <ng-option [value]="1/1">1:1</ng-option>
            <!--suppress PointlessArithmeticExpressionJS -->
            <ng-option [value]="2/1">2:1</ng-option>
            <!--suppress PointlessArithmeticExpressionJS -->
            <ng-option [value]="16/9">16:9</ng-option>
            <!--suppress PointlessArithmeticExpressionJS -->
            <ng-option [value]="4/3">4:3</ng-option>
        </ng-select>
    </div>

    <image-cropper
        [imageURL]="fileUrl"
        format="jpeg"
        [maintainAspectRatio]="aspectRatio !== ImageEditComponent.NO_ASPECT_RATIO"
        [aspectRatio]="aspectRatio"
        (imageCropped)="imageCropped($event)"
    ></image-cropper>

    <div class="buttons right mat-dialog-actions" mat-dialog-actions>
        <button (click)="save()"
                class="button primary"
                mat-raised-button color="accent"
                [disabled]="loading"
                trans>Speichern</button>
    </div>
</div>
