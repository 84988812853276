import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileEntry} from '@common/uploads/types/file-entry';
import {ImageEditComponent} from '@common/media/image-edit/image-edit.component';
import {MediaContext} from '@common/media/media-context';
import {Modal} from '@common/core/ui/dialogs/modal.service';

@Component({
    selector: 'media-grid-card',
    templateUrl: './media-grid-card.component.html',
    styleUrls: ['./media-grid-card.component.scss']
})
export class MediaGridCardComponent {
    @Input() entry: Partial<FileEntry>;
    @Input() selected = false;
    @Input() selectable = false;

    @Output() selectionChange = new EventEmitter<Partial<FileEntry>>();
    @Output() fileUploaded = new EventEmitter<FileEntry>();

    constructor(private modal: Modal,
                private mediaContext: MediaContext) {
    }

    onSelectionChange() {
        this.selectionChange.emit(this.entry);
    }

    editImage(entry: Partial<FileEntry>): void {
        this.openImageInModal(entry.name, entry.url);
    }

    private openImageInModal(name: string, url: string) {
        const dialogData = {
            name,
            fileUrl: url,
            mediaContext: this.mediaContext
        };

        this.modal.open(ImageEditComponent, dialogData)
            .afterClosed()
            .subscribe(
                uploadedImage => {
                    if (uploadedImage != null) {
                        this.fileUploaded.emit(uploadedImage);
                    }
                }
        );
    }
}
