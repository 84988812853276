import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Projects} from '../../../app/shared/projects/projects.service';
import {finalize, first, map, shareReplay} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {FileEntry, FileEntryType} from '@common/uploads/types/file-entry';
import {SelectionModel} from '@angular/cdk/collections';
import {
    extractFileNameOfPath,
    getFileTypeFromPath,
    getFolderPathLevel,
    getFoldersFromPath,
    removeTopPathLevels
} from '@common/core/utils/file-util';
import {OWNERSHIP_TYPE_PROJECT} from '@common/uploads/types/ownership';
import {
    FileEntryUploadButtonComponent
} from '@common/admin/file-entry/file-entry-upload-button/file-entry-upload-button.component';
import {MediaGridComponent} from '@common/media/media-grid/media-grid.component';
import {ConfirmModalComponent} from '@common/core/ui/confirm-modal/confirm-modal.component';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {CurrentUser} from '@common/auth/current-user';
import {MediaContext} from '@common/media/media-context';

const directoryFileSort = (a: FileEntry, b: FileEntry, fileSortFn: (a: FileEntry, b: FileEntry) => number): number => {
    const pathCompare = a.path.localeCompare(b.path);
    if (pathCompare === 0) {
        return fileSortFn(a, b);
    }
    return pathCompare;
};

const sortOptions = [{
    label: 'Name (A - Z)',
    sortFn: (a: FileEntry, b: FileEntry): number => {
        return directoryFileSort(a, b, (a1, b1) => a1.name.localeCompare(b1.name));
    },
}, {
    label: 'Name (Z - A)',
    sortFn: (a: FileEntry, b: FileEntry): number => {
        return directoryFileSort(a, b, (a1, b1) => b1.name.localeCompare(a1.name));
    },
}, {
    label: 'Hochladedatum (neueste - älteste)',
    sortFn: (a: FileEntry, b: FileEntry): number => {
        return directoryFileSort(a, b, (a1, b1) => b1.updated_at.localeCompare(a1.updated_at));
    },
}, {
    label: 'Hochladedatum (älteste - neueste)',
    sortFn: (a: FileEntry, b: FileEntry): number => {
        return directoryFileSort(a, b, (a1, b1) => a1.updated_at.localeCompare(b1.updated_at));
    },
}];

const filterOptions = [{
    label: 'Alle',
    filterType: undefined,
    filterFn: (_element: FileEntry): boolean => {
        return true;
    }
}, {
    label: 'Bild',
    filterType: FileEntryType.image,
    filterFn: (element: FileEntry): boolean => {
        return element.type === FileEntryType.image;
    }
}, {
    label: 'Video',
    filterType: FileEntryType.video,
    filterFn: (element: FileEntry): boolean => {
        return element.type === FileEntryType.video;
    }
}, {
    label: 'PDF',
    filterType: FileEntryType.pdf,
    filterFn: (element: FileEntry): boolean => {
        return element.type === FileEntryType.pdf;
    }
}, {
    label: 'Datei',
    filterType: FileEntryType.file,
    filterFn: (element: FileEntry): boolean => {
        return element.type === FileEntryType.file;
    }
}];

@Component({
    selector: 'project-media',
    templateUrl: './project-media.component.html',
    styleUrls: ['./project-media.component.scss'],
    providers: [MediaContext]
})
export class ProjectMediaComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() projectId?: number;
    @Input() title?: string;
    @Input() filterType?: FileEntryType;
    @Input() mode: 'view' | 'select' | 'manage' = 'view';

    @Output() selectionChange = new EventEmitter<SelectionModel<Partial<FileEntry>>>();

    @ViewChild(FileEntryUploadButtonComponent, {static: true}) uploadButton: FileEntryUploadButtonComponent;
    @ViewChild(MediaGridComponent, {static: true}) mediaGrid: MediaGridComponent;

    ownershipType = OWNERSHIP_TYPE_PROJECT;
    allFiles$: Observable<Partial<FileEntry>[]>;
    allFolderPaths$ = new BehaviorSubject<string[]>([]);
    currentFolderPath$ = new BehaviorSubject<string>('/');
    visibleFiles$: Observable<Partial<FileEntry>[]>;
    visibleFolders$: Observable<string[]>;
    loading = false;
    selection?: SelectionModel<Partial<FileEntry>>;
    sortOrFilterChange$ = new BehaviorSubject(true);

    sortOptions = sortOptions;
    filterOptions = filterOptions;
    selectedSortOption = sortOptions[2];
    selectedFilterOption = filterOptions[0];

    constructor(
        private ck: ChangeDetectorRef,
        private modal: Modal,
        private projectService: Projects,
        private currentUser: CurrentUser,
        private mediaContext: MediaContext
    ) {
    }

    ngOnInit(): void {
        if (this.filterType != null) {
            this.filterOptions = this.filterOptions.filter(option => option.filterType === this.filterType);
            this.selectedFilterOption = this.filterOptions.find(option => option.filterType === this.filterType);
        }
    }

    ngAfterViewInit(): void {
        this.selection = this.mediaGrid.selection;
    }

    ngOnChanges(): void {
        this.mediaContext.setContext('media/project/' + this.projectId, this.ownershipType, String(this.projectId));
        void this.loadMedia();
    }

    async loadMedia(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.projectId == null) return;

            this.loading = true;
            this.allFiles$ = this.projectService.listMedia(this.projectId).pipe(
                map(response => response.files),
                map(filesInfo => {
                    return filesInfo.map(fileInfo => ({
                        name: extractFileNameOfPath(fileInfo.filePath),
                        url: fileInfo.filePath,
                        type: getFileTypeFromPath(fileInfo.filePath),
                        path: removeTopPathLevels(getFoldersFromPath(fileInfo.filePath), 4),
                        updated_at: new Date(fileInfo.lastModified).toISOString()
                    } as Partial<FileEntry>));
                }),
                first(),
                finalize(() => {
                    this.loading = false;
                    resolve();
                }),
                shareReplay(1)
            );

            combineLatest([
                this.allFiles$,
            ]).pipe(
                map(([fileEntries]) => {
                    const folderPaths = new Set<string>();
                    (fileEntries ?? []).forEach((fileEntry) => {
                        // add all file paths, so that empty folders in the "middle" are also available
                        const pathParts = fileEntry.path.split('/');
                        pathParts.reduce((curPath, pathPart) => {
                            folderPaths.add(curPath);
                            if (pathPart === '') { // for first and last entry
                                return curPath;
                            }
                            return curPath + pathPart + '/';
                        }, '/');
                    });
                    return [...folderPaths].sort();
                })
            ).subscribe((allFolderPaths) => {
                this.allFolderPaths$.next(allFolderPaths);
            });

            this.allFolderPaths$.subscribe(() => {
                this.navigateFolderPath(this.currentFolderPath$.value);
            });

            this.visibleFiles$ = combineLatest([
                this.allFiles$,
                this.sortOrFilterChange$,
                this.currentFolderPath$,
            ]).pipe(
                map(([fileEntries, change]) => {
                    return (fileEntries ?? [])
                        .filter((fileEntry: Partial<FileEntry>) => {
                            // is in current folder
                            return fileEntry.path === this.currentFolderPath$.value;
                        })
                        .filter(this.selectedFilterOption.filterFn)
                        .sort(this.selectedSortOption.sortFn);
                })
            );

            this.visibleFolders$ = combineLatest([
                this.allFolderPaths$,
                this.currentFolderPath$,
            ]).pipe(
                map(([allFolderPaths, currentFolderPath]) => {
                    const currentFolderPathLevel = getFolderPathLevel(currentFolderPath);
                    const folders: string[] = [];

                    if (currentFolderPath !== '/') {
                        folders.push('..');
                    }

                    const currentFolderPathFolders = allFolderPaths
                        .filter(folderPath => {
                            return folderPath.startsWith(currentFolderPath);
                        })
                        .filter(folderPath => {
                            return getFolderPathLevel(folderPath) === currentFolderPathLevel + 1;
                        })
                        .map(folderPath => {
                            const pathParts = folderPath.split('/');
                            return pathParts[pathParts.length - 2];
                        });

                    return folders.concat(currentFolderPathFolders);
                })
            );
        });
    }

    onSelectionChange(selection: SelectionModel<Partial<FileEntry>>) {
        this.selection = selection;
        this.selectionChange.emit(selection);
    }

    onSortOrFilterChange() {
        this.sortOrFilterChange$.next(true);
    }

    async filesUploaded(data?: FileEntry[]) {
        await this.loadMedia();

        const file = data[0];
        if (file != null) {
            const folder = removeTopPathLevels(getFoldersFromPath(file.url), 4);
            this.currentFolderPath$.next(folder);
        }
    }

    filesDropped(files?: File[]) {
        this.uploadButton.showUploadModal(files);
    }

    canSelect() {
        return this.mode !== 'view';
    }

    canDelete() {
        return this.mode !== 'view' && this.hasSelection() && this.currentUser.hasPermission('media.project.delete');
    }

    hasSelection() {
        return this.selection?.hasValue() ?? false;
    }

    getSelectionCount() {
        return (this.selection?.selected ?? []).length;
    }

    maybeDeleteSelectedFiles() {
        this.modal.show(ConfirmModalComponent, {
            title: 'Delete Entries',
            body: 'Are you sure you want to delete selected entries?',
            ok: 'Delete'
        }).afterClosed().subscribe(confirmed => {
            if (!confirmed) return;
            this.deleteSelectedFiles();
        });
    }

    deleteSelectedFiles() {
        const filePaths = this.selection.selected.map(fileEntry => fileEntry.path.substring(1) + fileEntry.name);
        this.projectService.deleteMedia(this.projectId, filePaths).subscribe(() => {
            this.selection.clear();
            this.loadMedia();
            this.ck.markForCheck();
        });
    }

    navigateFolder(folderName: string) {
        let folderPath;
        if (folderName === '..') {
            const pathParts = this.currentFolderPath$.value.split('/');
            folderPath = pathParts.slice(0, pathParts.length - 2).join('/') + '/';
        } else {
            folderPath = `${this.currentFolderPath$.value}${folderName}/`;
        }
        this.currentFolderPath$.next(folderPath);
    }

    navigateFolderPath(folderPath?: string) {
        const allFolderPaths = this.allFolderPaths$.value;
        if (folderPath != null && (allFolderPaths ?? []).includes(folderPath)) {
            this.currentFolderPath$.next(folderPath);
        } else {
            this.currentFolderPath$.next('/');
        }
    }
}
