import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Toast} from '@common/core/ui/toast.service';
import {CurrentUser} from '@common/auth/current-user';
import {FileEntry, FileEntryType} from '@common/uploads/types/file-entry';
import {combineLatest, EMPTY, Observable} from 'rxjs';
import {Agency} from '../../../../app/shared/builder-types';
import {catchError, finalize, map, startWith} from 'rxjs/operators';
import {UploadQueueService} from '@common/uploads/upload-queue/upload-queue.service';
import {UploadApiConfig} from '@common/uploads/types/upload-api-config';
import {UploadedFile} from '@common/uploads/uploaded-file';
import {
    OWNERSHIP_ID_PERMISSION_AGENCY,
    OWNERSHIP_ID_PERMISSION_GLOBAL,
    OWNERSHIP_ID_PERMISSION_SYSTEM,
    OWNERSHIP_TYPE_AGENCY,
    OWNERSHIP_TYPE_PERMISSION,
    OWNERSHIP_TYPE_PROJECT
} from '@common/uploads/types/ownership';
import {Project} from '../../../../app/shared/projects/Project';
import {Projects} from '../../../../app/shared/projects/projects.service';
import {
    DefaultUploadValidator
} from '@common/uploads/validation/default-upload-validator';
import {AgenciesApi} from '../../../../app/shared/agencies/agencies.service';

@Component({
    selector: 'file-entry-upload',
    templateUrl: './file-entry-upload.component.html',
    styleUrls: ['./file-entry-upload.component.scss'],
})
export class FileEntryUploadComponent implements OnInit {

    @Input() files?: File[];
    @Input() endpoint = 'uploads';
    @Input() acceptType?: FileEntryType;
    @Input() ownershipType?: string;
    @Input() ownershipId?: string;

    @Output() filesUploaded = new EventEmitter<FileEntry[]>();

    public model: {
        ID: number,
        ownershipType: string,
        ownershipId: string,
    };

    private ownershipOptionSystem = {
        value: 'permission--' + OWNERSHIP_ID_PERMISSION_SYSTEM,
        label: 'System',
        ownershipType: OWNERSHIP_TYPE_PERMISSION,
        ownershipId: OWNERSHIP_ID_PERMISSION_SYSTEM
    };
    private ownershipOptionGlobal = {
        value: 'permission--' + OWNERSHIP_ID_PERMISSION_GLOBAL,
        label: 'Global',
        ownershipType: OWNERSHIP_TYPE_PERMISSION,
        ownershipId: OWNERSHIP_ID_PERMISSION_GLOBAL
    };

    public ownershipSpecified = false;
    public selectedOwnershipOption;
    public ownershipOptions$: Observable<any[]>;

    public loading = false;

    /**
     * Errors returned from backend.
     */
    public errors: any = {};

    constructor(
        private uploadQueue: UploadQueueService,
        public agencies: AgenciesApi,
        public projects: Projects,
        public currentUser: CurrentUser,
        private toast: Toast,
        private defaultUploadValidator: DefaultUploadValidator,
    ) {
    }

    ngOnInit() {
        this.resetState();
        this.hydrateModel();

        if (this.ownershipType != null && this.ownershipId != null) {
            this.ownershipSpecified = true;

            this.model.ownershipType = this.ownershipType;
            this.model.ownershipId = this.ownershipId;
        } else {
            this.ownershipOptions$ = combineLatest([
                this.agencies.all().pipe(
                    map(response => response.pagination.data),
                    startWith([] as Agency[])
                ),
                this.projects.listSimpleAll().pipe(
                    map(response => response.pagination.data),
                    startWith([] as Project[])
                )
            ]).pipe(
                map(([agencies, projects]) => {

                    const sharedOptions = [];
                    const hasSystemPermission = this.currentUser.hasPermission(OWNERSHIP_ID_PERMISSION_SYSTEM);
                    if (hasSystemPermission) {
                        sharedOptions.push(this.ownershipOptionSystem);
                    }
                    const hasGlobalPermission = this.currentUser.hasPermission(OWNERSHIP_ID_PERMISSION_GLOBAL);
                    if (hasGlobalPermission) {
                        sharedOptions.push(this.ownershipOptionGlobal);
                    }

                    let agencyOptions = [];
                    const hasAgencyPermission = this.currentUser.hasPermission(OWNERSHIP_ID_PERMISSION_AGENCY);
                    if (hasAgencyPermission) {
                        agencyOptions = (agencies ?? []).map(agency => ({
                            value: 'agency--' + agency.id,
                            label: agency.name,
                            ownershipType: OWNERSHIP_TYPE_AGENCY,
                            ownershipId: agency.id,
                        }));
                    }

                    const projectOptions = (projects ?? []).map(project => ({
                        value: 'project--' + project.id,
                        label: `Projekt: ${project.name}`,
                        ownershipType: OWNERSHIP_TYPE_PROJECT,
                        ownershipId: project.id,
                    }));

                    const optionGroups = [sharedOptions, agencyOptions, projectOptions];
                    return optionGroups.filter(group => group.length > 0).reduce((acc, cur) => {
                        if (acc.length > 0) {
                            acc.push(undefined);
                        }
                        return acc.concat(cur);
                    }, []);
                })
            );
        }
    }

    /**
     * Create a new item or update existing one.
     */
    public confirm() {
        this.loading = true;

        const uploadedFiles = (this.files ?? []).map(file => UploadedFile.fromFile(file));

        const uploadConfig: UploadApiConfig = {
            httpParams: {
                ownershipType: this.model.ownershipType,
                ownershipId: this.model.ownershipId,
            },
            uri: this.endpoint,
            validator: this.defaultUploadValidator,
        };
        const request = this.uploadQueue.start(uploadedFiles, uploadConfig);

        const completedUploadedFiles = [];
        request.pipe(
            catchError((response) => {
                this.errors = response.messages;
                return EMPTY;
            }),
            finalize(() => this.loading = false)
        ).subscribe(response => {
            completedUploadedFiles.push(response.fileEntry);

            if (uploadedFiles.length === 1 && completedUploadedFiles[0].extension === 'pdf') {
                this.toast.open(`${completedUploadedFiles[0].file_name} has been uploaded`);
            } else {
                this.toast.open(`Item ${completedUploadedFiles.length}/${uploadedFiles.length} has been uploaded`);
            }

            if (completedUploadedFiles.length === uploadedFiles.length) {
                this.filesUploaded.emit([response.fileEntry]);
            }
        });
    }

    /**
     * Populate item model with given data.
     */
    private hydrateModel() {
    }

    /**
     * Reset all modal state to default.
     */
    private resetState() {
        this.model = {
            ID: undefined,
            ownershipType: '',
            ownershipId: '',
        };
        this.errors = {};
    }

    public setFiles(files?: File[]) {
        this.files = files;
        this.errors = {};
    }

    public ownershipChanged(option) {
        if (option != null) {
            this.model.ownershipType = option.ownershipType;
            this.model.ownershipId = option.ownershipId;
        } else {
            this.model.ownershipType = '';
            this.model.ownershipId = '';
        }
    }

    canUpload() {
        return !this.loading && this.files?.length > 0;
    }
}
