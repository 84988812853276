import {getFileMime} from './utils/get-file-mime';
import {extensionFromFilename} from './utils/extension-from-filename';
import {getBase64Mime} from '@common/uploads/utils/get-base64-mime';

export class UploadedFile {
    name: string;
    relativePath: string;
    size: number;
    mime: string;
    extension: string;
    native: Blob;
    lastModified: number;
    url: string|null = null;

    static fromFile(file: File, relativePath?: string): UploadedFile {
        const result = new UploadedFile();

        result.name = file.name;
        result.size = file.size;
        result.mime = getFileMime(file);
        result.lastModified = file.lastModified;
        result.extension = extensionFromFilename(file.name);
        result.native = file;

        relativePath = relativePath || file.webkitRelativePath || null;
        // only include relative path if file is actually in a folder and not just /file.txt
        if (relativePath && relativePath.match(/\//g).length > 1) {
            result.relativePath = relativePath;
        }

        return result;
    }

    static fromBase64(content: string, name: string): UploadedFile {
        const result = new UploadedFile();
        const file = this.base64ToFile(content, name);

        result.name = name;
        result.size = file.size;
        result.mime = getBase64Mime(content);
        result.lastModified = Date.now();
        result.extension = extensionFromFilename(name);
        result.native = file;

        return result;
    }

    private static base64ToFile(base64String: string, name: string): File {
        // Remove data URL scheme if present
        const base64Data = base64String.replace(/^data:.+;base64,/, '');
        const byteCharacters = atob(base64Data); // Decode Base64 string
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        return new File([byteArray], name, { type: getBase64Mime(base64String) });
    }

    public getData(): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();

            reader.addEventListener('load', () => {
                resolve(reader.result as string);
            });

            if (this.extension === 'json') {
                reader.readAsText(this.native);
            } else {
                reader.readAsDataURL(this.native);
            }
        });
    }
}
