import {Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {FileEntry} from '@common/uploads/types/file-entry';
import {SelectionModel} from '@angular/cdk/collections';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MediaContext} from '@common/media/media-context';
import {UploadedFile} from '@common/uploads/uploaded-file';
import {UploadApiConfig} from '@common/uploads/types/upload-api-config';
import {catchError, finalize} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {DefaultUploadValidator} from '@common/uploads/validation/default-upload-validator';
import {UploadQueueService} from '@common/uploads/upload-queue/upload-queue.service';

@Component({
    selector: 'image-edit',
    templateUrl: './image-edit.component.html',
    styleUrls: ['./image-edit.component.scss'],
})
export class ImageEditComponent {
    public static NO_ASPECT_RATIO = -1;

    ImageEditComponent = ImageEditComponent;

    @Input() name: string;
    @Input() fileUrl: string;
    @Input() mediaContext: MediaContext;

    @Output() folderClick = new EventEmitter<string>();

    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

    public loading = false;
    public selection: SelectionModel<Partial<FileEntry>>;
    width: number;
    height: number;
    aspectRatio = ImageEditComponent.NO_ASPECT_RATIO;

    constructor(
        private dialogRef: MatDialogRef<ImageEditComponent>,
        private defaultUploadValidator: DefaultUploadValidator,
        private uploadQueue: UploadQueueService,
        @Inject(MAT_DIALOG_DATA) data) {

        this.name = data.name;
        this.fileUrl = data.fileUrl;
        this.mediaContext = data.mediaContext;
    }

    imageCropped($event: ImageCroppedEvent): void {
        this.width = $event.width;
        this.height = $event.height;
    }

    sizeChanged(): void {
        this.imageCropper.cropper = {
            x1: this.imageCropper.cropper.x1,
            y1: this.imageCropper.cropper.y1,
            x2: this.imageCropper.cropper.x1 + this.width,
            y2: this.imageCropper.cropper.y1 + this.height,
        };

        this.aspectRatio = ImageEditComponent.NO_ASPECT_RATIO;
    }

    save(): void {
        this.loading = true;

        const uploadedFile = UploadedFile.fromBase64(this.imageCropper.crop().base64, 'cropped-image.jpg');

        const uploadConfig: UploadApiConfig = {
            httpParams: {
                ownershipType: this.mediaContext.ownershipType,
                ownershipId: this.mediaContext.ownershipId,
            },
            uri: this.mediaContext.endpoint,
            validator: this.defaultUploadValidator,
        };
        const request = this.uploadQueue.start([uploadedFile], uploadConfig);

        request.pipe(
            catchError(() => {
                return EMPTY;
            }),
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.dialogRef.close(response.fileEntry);
        });
    }
}
