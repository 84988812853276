import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileEntry} from '@common/uploads/types/file-entry';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
    selector: 'media-grid',
    templateUrl: './media-grid.component.html',
    styleUrls: ['./media-grid.component.scss'],
})
export class MediaGridComponent implements OnInit {

    @Input() files?: Partial<FileEntry>[];
    @Input() folders?: string[];
    @Input() selectable = false;
    @Input() multiselect = false;

    @Output() selectionChange = new EventEmitter<SelectionModel<Partial<FileEntry>>>();
    @Output() folderClick = new EventEmitter<string>();
    @Output() fileUploaded = new EventEmitter<FileEntry>();

    public selection: SelectionModel<Partial<FileEntry>>;

    ngOnInit(): void {
        this.selection = new SelectionModel<Partial<FileEntry>>(this.multiselect, []);
    }

    onCardSelect(file: Partial<FileEntry>) {
        this.selection.toggle(file);
        this.selectionChange.emit(this.selection);
    }

    onFolderClick(folderName: string) {
        this.folderClick.emit(folderName);
    }
}
