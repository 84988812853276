/**
 * Extracts the MIME type from a base64-encoded data URI string.
 *
 * @param content The data URI string to extract the MIME type from
 * @return The MIME type if the input is a valid data URI, or {@code null} if the input
 *         does not start with the 'data:' prefix
 *
 * @example
 * // Returns "image/jpeg"
 * String mimeType = getBase64Mime("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEA...");
 *
 * @example
 * // Returns null
 * String mimeType = getBase64Mime("https://example.com/image.jpg");
 */
export function getBase64Mime(content: string): string | null {
    if (!content.startsWith('data:')) {
        return null;
    }

    const start = 'data:'.length;
    const end = content.indexOf(';');

    return content.substring(start, end);
}
